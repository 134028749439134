<template>
  <v-container fluid>
    <v-dialog v-model="this.$store.getters['Auth/is_doc_open']" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <h4 class="headline">{{ formTitle }}</h4>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="colll" cols="12" sm="12" md="12">
                <v-select
                  :items="this.$store.getters['Auth/get_all_legal_form'].document_types"
                  v-model="doc.type"
                  label="Sélectionner le type de dossier"
                  item-text="description"
                  item-value="status"
                ></v-select>
                <v-text-field v-model="doc.object" label="Saisir l’objet du dossier"></v-text-field>
                <date-picker
                  v-model="doc.publi_date"
                  value-type="format"
                  format="YYYY-MM-DD H:m:s"
                  type="datetime"
                  :default-value="new Date()"
                  placeholder="Renseigner la date/heure de publication du dossier"
                ></date-picker>
              </v-col>
              <v-col class="colll" cols="12" sm="12" md="12">
                <date-picker
                  v-model="doc.deadline"
                  value-type="format"
                  format="YYYY-MM-DD H:m:s"
                  :default-value="new Date()"
                  type="datetime"
                  placeholder="Renseigner la date/heure limite de dépôt des plis"
                ></date-picker>
              </v-col>
              <v-col
                v-if="doc.type != 5 && doc.type != 2 && doc.type != 3"
                cols="12"
                sm="12"
                md="12"
              >
                <date-picker
                  v-if="doc.type != 4"
                  v-model="doc.date_opening"
                  value-type="format"
                  :default-value="new Date()"
                  format="YYYY-MM-DD H:m:s"
                  type="datetime"
                  placeholder="Renseigner la date/heure d’ouverture des plis"
                ></date-picker>
                <date-picker
                  v-model="doc.date_opening"
                  value-type="format"
                  v-if="doc.type == 4"
                  format="YYYY-MM-DD H:m:s"
                  :default-value="new Date()"
                  type="datetime"
                  placeholder="Renseigner la date/heure d’ouverture des Propositions Techniques"
                ></date-picker>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  :items="listNoticeFilteredFromDocId()"
                  v-model="doc.notice"
                  v-on:change="UsersToSelect()"
                  label="Sélectionner l’avis spécifique du dossier d’appel à la concurrence"
                  :disabled="noticeType.status === 1"
                  item-text="object"
                  item-value="id"
                >
                </v-select>
                <v-switch
                  v-model="doc.free"
                  :label="
                    `Prix de vente du dossier de consultation: ${!doc.free ? 'Payant' : 'Gratuit'}`
                  "
                ></v-switch>
                <v-select
                  v-if="
                    doc.type == 1 ||
                      doc.type == 4 ||
                      doc.type == 6 ||
                      doc.type == 7 ||
                      this.$store.getters['Auth/get_selected_notice']._is_elegible
                  "
                  :items="this.$store.getters['Auth/get_selected_notice']._is_elegible"
                  item-text="firstname"
                  multiple
                  v-model="doc.users_retained"
                  :hint="
                    `Nombre de manifestations d’intérêts reçues : ${
                      this.$store.getters['Auth/get_selected_notice']._is_elegible
                        ? this.$store.getters['Auth/get_selected_notice']._is_elegible.length
                        : '0'
                    } `
                  "
                  item-value="id"
                  label="Sélectionner les consultants retenus sur la liste restreinte"
                  persistent-hint
                  single-line
                ></v-select>
                <v-file-input
                  v-model="doc.file"
                  placeholder="Charger le fichier"
                  label="Insérer le fichier du dossier de consultation"
                  multiple
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">{{ text }}</v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close_doc">ANNULER LA PUBLICATION</v-btn>
          <v-btn
            color="green darken-1"
            :loading="this.$store.getters['Auth/loadDoc']"
            text
            @click="addthisNotice"
            >PUBLIER DE DOSSIER</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      locale="fr"
      :headers="headers"
      @click:row="goto"
      :search="search"
      no-data-text="Aucun dossiers de consultations disponible"
      :items="this.$store.getters['Auth/getListDoc']"
      sort-by="createdAt"
      class="elevation-1 mb-5 px-5"
    >
      <template v-slot:[`item.is_free`]="{ item }">
        <v-chip label small outlined :color="`${item.free ? 'green' : 'red'}`">
          {{ `${item.free ? "Gratuit" : "Payant"}` }}
        </v-chip>
      </template>
      <template v-slot:[`item.deadline`]="{ item }">
        {{ item.deadline | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}
      </template>
      <template v-slot:[`item.publiDate`]="{ item }">
        {{ item.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}
      </template>
      <template v-slot:[`item.date_opening`]="{ item }">
        {{
          item.date_opening ? item.date_opening : "--" | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
        }}
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-5">
          <v-toolbar-title v-show="$vuetify.breakpoint.mdAndUp">Liste détaillée des dossiers de consultations</v-toolbar-title>
          <v-divider class="mx-4" inset vertical v-show="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherchez un dossier de consultation"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn small color="primary" dark class="mb-2" @click="open_doc">
            <v-icon small class="mr-2">mdi-plus</v-icon>AJOUTER UN NOUVEAU DOSSIER DE CONSULTATION
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { DatePicker },
  data: () => ({
    search: "",
    NoticeFiltered: [],
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Documents",
        disabled: true
      }
    ],
    dog: true,
    noticeType: [], 
    doc: {
      object: "",
      publi_date: null,
      users_retained: [],
      deadline: null,
      date_opening: null,
      type: [],
      notice: [],
      free: true,
      file: []
    },
    dialog: false,
    headers: [
      {
        text: "Prix du dossier",
        align: "start",
        sortable: true,
        value: "is_free"
      },
      {
        text: "Objet du dossier",
        sortable: true,
        value: "object"
      },
      {
        text: "Date/heure de publication du dossier",
        sortable: true,
        value: "publiDate"
      },
      { text: "Date/heure limite de dépôt des Offres /Propositions/ Plis", value: "deadline" },
      { text: "Type de dossier de consultation", value: "documentType.title" },
      { text: "Date/heure d’ouverture des Offres /Propositions/Plis", value: "date_opening", sortable: false }
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: "",
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Création d’un nouvel dossier de consultation"
        : "Création du Plan de Passation des Marchés";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.$store.dispatch("Auth/loadAllNotice");
    this.$store.dispatch("Auth/loadAllDoc");
    this.$store.dispatch("Auth/getData");
  },
  methods: {
    ...mapActions({
      addDoc: "Auth/addDoc",
      loadAllNotice: "Auth/loadAllNotice"
    }),

    open_doc() {
      this.$store.commit("Auth/OPEN_DOC", true);
    },
    //FONCTION DE VERIFICATION LIEN AVEC DOSSIER
    listNoticeFilteredFromDocId() {
      return this.$store.getters["Auth/getListAvis"].filter(item => {
        switch (this.doc.type) {
          case 1:
            return item.noticeType.status == 2;
          case 2:
            return item.noticeType.status == 3;
          case 3:
            return item.noticeType.status == 4;
          case 4:
            return item.noticeType.status == 6;
          case 5:
            return item.noticeType.status == 5;
          case 6:
            return item.noticeType.status == 7;
          case 7:
            return item.noticeType.status == 7;
        }
      });
    },
    async UsersToSelect() {
      await this.listNoticeFilteredFromDocId();
      if (this.doc.notice) {
        this.$store.dispatch("Auth/getNotice", this.doc.notice);
      }
    },
    close_doc() {
      this.$store.commit("Auth/OPEN_DOC", false);
    },
    goto(value) {
      const { id } = value;
      this.$router.push({ name: "showFolder", params: { id: id } });
    },
    addthisNotice() {
      console.log(this.doc.users_retained);
      const all_users = this.$store.getters["Auth/get_selected_notice"].download_list.map(
        a => a.user.id
      );
      let users_not_retained = all_users.filter(item => !this.doc.users_retained.includes(item));
      const data = new FormData();
      data.append("object", this.doc.object);
      data.append("doc_type", this.doc.type);
      data.append("notice_id", this.doc.notice);
      if (this.doc.users_retained.length) {
        data.append("selected_users", this.doc.users_retained.toString());
      }
      if (users_not_retained.length) {
        data.append("not_selected_users", users_not_retained.toString());
      }

      data.append("is_free", this.doc.free);
      data.append("publi_date", this.doc.publi_date);
      if (this.doc.date_opening) {
        data.append("date_opening", this.doc.date_opening);
      }
      data.append("deadline", this.doc.deadline);
      if (this.doc.file) {
        data.append("file", this.doc.file[0]);
      } else {
        data.append("file", null);
      }
      this.addDoc(data);

      this.doc.object = "";
      this.doc.publi_date = null;
      this.doc.users_retained = [];
      this.doc.deadline = null;
      this.doc.date_opening = null;
      this.doc.type = [];
      this.doc.notice = [];
      this.doc.free = true;
      this.doc.file = [];
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") && this.desserts.splice(index, 1);
    },
    getPPM(item) {
      console.log(item);
      this.$router.push({ name: "user", params: { ppm: item } });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>
<style scoped>
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
.v-card__text {
  width: 100% !important;
}
</style>
