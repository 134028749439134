<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="5">
        <v-card class="pa-2">
          <v-card-title>
            <h4>
              Détail du dossier concerné
              {{ this.$store.getters["Auth/get_selected_doc"].document.documentType.description }}
            </h4>
            <v-spacer></v-spacer>
          <v-chip label small outlined :color="`${this.$store.getters['Auth/get_selected_doc'].document.free ? 'green' : 'red'}`">
          {{ `${this.$store.getters['Auth/get_selected_doc'].document.free ? "Gratuit" : "Payant"}` }}
        </v-chip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Objet du dossier :</b>
            {{ this.$store.getters["Auth/get_selected_doc"].document.object }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure de publication du dossier :</b>
            {{
              this.$store.getters["Auth/get_selected_doc"].document.publiDate
                | moment("dddd, Do MMMM YYYY à hh:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure limite de remise des Offres/Propositions/plis :</b>
            {{
              this.$store.getters["Auth/get_selected_doc"].document.deadline
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure d’ouverture des Offres/Propositions/plis :</b>
            {{
              this.$store.getters["Auth/get_selected_doc"].document.date_opening
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
            <v-spacer class="my-1"></v-spacer>
            <div
              v-if="
                this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 2 &&
                  this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 3 &&
                  this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 5
              "
            >
              <b>Nombre de Consultants/Cabinets retenus sur la liste restreinte • </b>
              {{ this.$store.getters["Auth/get_selected_doc"].document.selected_users.length }}
              <v-spacer class="my-1"></v-spacer>
              <b>Nombre de Manifestations d’Intérêts reçues • </b>
              {{
                this.$store.getters["Auth/get_selected_doc"].document.not_selected_users.length +
                  this.$store.getters["Auth/get_selected_doc"].document.selected_users.length
              }}
              <v-spacer class="my-1"></v-spacer>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-title>
            <h4>Modification de la date/l’heure limite de dépôt ou d’ouverture des Offres/Propositions/Plis</h4>
            <v-spacer></v-spacer>
            <v-chip
              v-if="this.$store.getters['Auth/get_selected_doc'].document.date_has_been_updated"
              >R</v-chip
            >
          </v-card-title>
          <v-card-text>
            <v-select
              :items="this.$store.getters['Auth/get_all_legal_form'].date_type"
              label="Sélectionner l’option appropriée"
              v-model="report.type"
              item-text="name"
              item-value="id"
            ></v-select>
            <date-picker
              v-model="report.date"
              value-type="format"
              format="YYYY-MM-DD H:m:s"
              :default-value="new Date()"
              type="datetime"
              placeholder="Définir la nouvelle date et heure"
            ></date-picker>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="white--text" block @click="addReport">
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>Soumettre
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          v-if="
            this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 2 &&
              this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 3 &&
              this.$store.getters['Auth/get_selected_doc'].document.documentType.status != 5
          "
          class="mt-2  "
        >
          <v-card-title><h4>Transmission de lettre aux consultants/candidats</h4></v-card-title>
          <v-card-text>
            <v-select
              :items="this.$store.getters['Auth/get_all_legal_form'].letter_type"
              label="Sélectionner le type de lettre à envoyer aux consultants/candidats"
              v-model="letter.type"
              item-text="description"
              item-value="status"
            ></v-select>
            <v-file-input
              v-model="letter.file"
              placeholder="Charger le fichier"
              label="Joindre la lettre à envoyer"
              multiple
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" small class="white--text " block @click="addLetter">
              <v-icon small class="mr-2" dark>mdi-cloud-upload</v-icon>ENVOYER LA LETTRE AUX CONSULTANTS/CANDIDATS CONCERNES
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-2">
          <v-card-title> <h4>Ajouter un additif</h4> </v-card-title>
          <v-card-text>
            <v-text-field v-model="addi.objet" label="Saisir l’objet de l’additif"></v-text-field>
            <v-file-input
              v-model="addi.file"
              placeholder="Charger le fichier de l'additif"
              label="Joindre le dossier relatif à l’additif"
              multiple
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block class="white--text" @click="addExtension">
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>Publier l'additif
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-2">
          <v-card-title
            ><h4>Traçabilité des téléchargements du dossier concerné</h4>
            <v-spacer></v-spacer
            ><v-chip class="ma-2">{{
              this.$store.getters["Auth/get_selected_doc"].downloaded_nb
            }}</v-chip>
          </v-card-title>
          <v-card-text>
            <v-list-item v-for="item in traceability()" :key="item.id">
              <v-list-item-content>
                <v-list-item-subtitle class="text-truncate font-weight-bold">
                  Type : {{ item.user.company ? "Entreprise" : "Individu" }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="text-truncate font-weight-bold"
                  v-text="item.user.email"
                ></v-list-item-subtitle>
                <v-list-item-subtitle class="">
                  {{ item.loadedAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-row>
          <v-col class="pb-0">
            <v-card v-if="this.$store.getters['Auth/get_selected_doc'].document.notice">
              <v-card-title>
                <h4>Référence de l’avis rattaché au dossier</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-list-item class="block text-truncate">
                    <v-list-item-content>
                      <v-list-item-title
                        class="primary--text text-truncate font-weight-bold"
                        v-text="this.$store.getters['Auth/get_selected_doc'].document.notice.object"
                      ></v-list-item-title>

                      <v-list-item-subtitle class="text-truncate secondary--text">{{
                        this.$store.getters["Auth/get_selected_doc"].document.notice.publiDate
                          | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="mb-2">
              <v-card-title>
                <h4>Liste des additifs rattachés au dossier</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-list-item v-for="item in this.$store.getters['Auth/getDocAdd']" :key="item.id">
                    <v-list-item-content>
                      <v-list-item-title
                        class="primary--text text-truncate font-weight-bold"
                        v-text="item.object"
                      ></v-list-item-title>
                      <v-list-item-subtitle class="secondary--text">
                        {{ item.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="grey lighten-1" @click="ShowTheRevision(item)"
                          >mdi-eye</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card v-if="!additif">
          <v-card-title>
            <h4>
              <!--Aperçu -->
              {{ this.$store.getters["Auth/get_selected_doc"].document.documentType.description }}
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 62em !important; overflow: hidden">
            <VueDocPreview
              style="height: 100%"
              v-if="
                this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'doc' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'docx' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'xls' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'xlsx' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'XLS' ||
                  this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'XLSX'
              "
              :value="this.$store.getters['Auth/get_selected_doc'].document.fileLink"
              type="office"
            />
            <vue-friendly-iframe
              v-if="this.$store.getters['Auth/get_selected_doc'].document.fileExtension == 'pdf'"
              :src="this.$store.getters['Auth/get_selected_doc'].document.fileLink"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
        <v-card v-if="additif">
          <v-card-title>
            <h4>{{ Tdr_Ob.object }}</h4>
            <v-spacer></v-spacer>
            <h6>Date : {{ Tdr_Ob.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}</h6>
          </v-card-title>
          <v-divider></v-divider>
          <v-divider></v-divider>
          <VueDocPreview
            v-if="
              Tdr_Ob.fileType == 'doc' ||
                Tdr_Ob.fileType == 'docx' ||
                Tdr_Ob.fileType == 'xls' ||
                Tdr_Ob.fileType == 'xlsx' ||
                Tdr_Ob.fileType == 'XLS' ||
                Tdr_Ob.fileType == 'XLSX'
            "
            :value="Tdr_Ob.fileLink"
            type="office"
          />
          <v-card-text>
            <v-row>
              <vue-friendly-iframe
                v-if="Tdr_Ob.fileType == 'pdf'"
                :src="Tdr_Ob.fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import { mapActions } from "vuex";
import VueDocPreview from "vue-doc-preview";

export default {
  components: { DatePicker, VueDocPreview },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    filtered: [],
    Tdr_Ob: "",
    addi: {
      objet: "",
      publi_date: new Date(),
      file: []
    },
    letter: {
      type: 0,
      file: []
    },
    report: {
      type: 0,
      date: null
    },
    ppm: [],
    additif: false,
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Documents",
        disabled: false,
        href: "/notice"
      },
      {
        text: "Détails du document",
        disabled: true
      }
    ]
  }),
  computed() {
    console.log(this.$store.getters["Auth/get_selected_doc"].documentType.title);
  },
  created() {
    this.$store.dispatch("Auth/getDoc", this.id);
    this.$store.dispatch("Auth/loadDocAdd", this.id);
  },
  methods: {
    ...mapActions({
      extension_addD: "Auth/extension_addD"
    }),
    traceability() {
      return this.$store.getters["Auth/get_selected_doc"].download_list;
    },
    async ShowTheRevision(tdr) {
      let td = tdr;
      this.Tdr_Ob = td;
      console.log(td);
      this.additif = !this.additif;
    },
    addExtension() {
      const data = new FormData();
      data.append("object", this.addi.objet);
      data.append("publi_date", moment(this.addi.publi_date).format("YYYY-MM-DD H:m:s"));
      if (this.addi.file) {  
        data.append("file", this.addi.file[0]);
      } else {
        data.append("file", null);
      }
      data.append("document", this.id);
      this.extension_addD(data);

      this.addi.objet = "";
      this.addi.publi_date = new Date();
      this.addi.file = [];
    },
    addReport() {
      const data = new FormData();
      data.append("doc_id", this.id);
      data.append("type_date_id", this.report.type);
      data.append("date", this.report.date);
      this.$store.dispatch("Auth/addReport_doc", data);

      this.report.date = null;
      this.report.type = 0;
    },
    addLetter() {
      const data = new FormData();
      data.append("doc_id", this.id);
      data.append("letter_type_id", this.letter.type);
      if (this.addi.file) {
        data.append("file", this.letter.file[0]);
      } else {
        data.append("file", null);
      }
      this.$store.dispatch("Auth/addLetter_doc", data);
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
</style>
